






















import { Component, Prop } from 'vue-property-decorator'
import AbstractField from '@/shared/classes/components/form/fields/abstract-field'
import MultiSelect from 'vue-multiselect'

import DataTableFilter from '@/shared/classes/components/data-table/data-table-filter';

@Component({
  components: { MultiSelect }
})
export default class CheckboxBlockField extends AbstractField {
  @Prop() filter!: DataTableFilter

  created() {
    this.value = typeof this.$route.query[this.filterKey] === 'string' ? [this.$route.query[this.filterKey]] : this.$route.query[this.filterKey]
  }

  get filterKey():string {
    if (!this.filter?.operator || !this.field?.key) return ''
    return `filter.${this.filter.operator}.${this.field.key}`
  }

  updateValue(newValue: string) {
    if (!Array.isArray(this.value)) this.value = []
    const index = this.value.indexOf(newValue);

    if (index !== -1) {
      this.value.splice(index, 1)
    } else {
      this.value.push(newValue)
    }
  }
}
